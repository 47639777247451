const ExpandMoreIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 16}
    height={heightIcon || 16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.3538 6.35375L8.35375 11.3538C8.30732 11.4002 8.25217 11.4371 8.19147 11.4623C8.13077 11.4874 8.06571 11.5004 8 11.5004C7.9343 11.5004 7.86923 11.4874 7.80853 11.4623C7.74783 11.4371 7.69269 11.4002 7.64625 11.3538L2.64625 6.35375C2.55243 6.25993 2.49973 6.13269 2.49973 6C2.49973 5.86732 2.55243 5.74007 2.64625 5.64625C2.74007 5.55243 2.86732 5.49973 3 5.49973C3.13269 5.49973 3.25993 5.55243 3.35375 5.64625L8 10.2931L12.6463 5.64625C12.6927 5.5998 12.7479 5.56295 12.8086 5.53781C12.8693 5.51267 12.9343 5.49973 13 5.49973C13.0657 5.49973 13.1308 5.51267 13.1915 5.53781C13.2521 5.56295 13.3073 5.5998 13.3538 5.64625C13.4002 5.69271 13.4371 5.74786 13.4622 5.80856C13.4873 5.86925 13.5003 5.93431 13.5003 6C13.5003 6.0657 13.4873 6.13076 13.4622 6.19145C13.4371 6.25215 13.4002 6.3073 13.3538 6.35375Z"
      fill="#707070"
    />
  </svg>
);

export default ExpandMoreIcon;
