import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const activation = (token, setDataActivation) => {
  const bodyRequest = {
    token: token,
  };

  const headers = headerRequest();
  RequestHttpGateway.post(`user-access/activation`, bodyRequest, {
    headers,
  })
    .then((res) => {
      setDataActivation(res.data);
    })
    .catch((err) => {
      setDataActivation(err.response.data);
    });
};

export default activation;
