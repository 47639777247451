const LogoutIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 20}
    height={heightIcon || 20}
    viewBox="0 0 18 19"
    fill="none"
  >
    <path
      d="M8.4375 15.6875C8.4375 15.8367 8.37824 15.9798 8.27275 16.0852C8.16726 16.1907 8.02418 16.25 7.875 16.25H3.375C3.22582 16.25 3.08274 16.1907 2.97725 16.0852C2.87176 15.9798 2.8125 15.8367 2.8125 15.6875V3.3125C2.8125 3.16332 2.87176 3.02024 2.97725 2.91475C3.08274 2.80926 3.22582 2.75 3.375 2.75H7.875C8.02418 2.75 8.16726 2.80926 8.27275 2.91475C8.37824 3.02024 8.4375 3.16332 8.4375 3.3125C8.4375 3.46168 8.37824 3.60476 8.27275 3.71025C8.16726 3.81574 8.02418 3.875 7.875 3.875H3.9375V15.125H7.875C8.02418 15.125 8.16726 15.1843 8.27275 15.2898C8.37824 15.3952 8.4375 15.5383 8.4375 15.6875ZM16.148 9.10203L13.3355 6.28953C13.2299 6.18398 13.0868 6.12469 12.9375 6.12469C12.7882 6.12469 12.6451 6.18398 12.5395 6.28953C12.434 6.39508 12.3747 6.53823 12.3747 6.6875C12.3747 6.83677 12.434 6.97992 12.5395 7.08547L14.3923 8.9375H7.875C7.72582 8.9375 7.58274 8.99676 7.47725 9.10225C7.37176 9.20774 7.3125 9.35082 7.3125 9.5C7.3125 9.64918 7.37176 9.79226 7.47725 9.89775C7.58274 10.0032 7.72582 10.0625 7.875 10.0625H14.3923L12.5395 11.9145C12.434 12.0201 12.3747 12.1632 12.3747 12.3125C12.3747 12.4618 12.434 12.6049 12.5395 12.7105C12.6451 12.816 12.7882 12.8753 12.9375 12.8753C13.0868 12.8753 13.2299 12.816 13.3355 12.7105L16.148 9.89797C16.2003 9.84573 16.2418 9.78369 16.2701 9.7154C16.2984 9.64712 16.3129 9.57392 16.3129 9.5C16.3129 9.42608 16.2984 9.35288 16.2701 9.2846C16.2418 9.21631 16.2003 9.15427 16.148 9.10203Z"
      fill="#EB5757"
    />
  </svg>
);
export default LogoutIcon;
