import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import HeaderComponent from "../General/Component/HeaderComponent";
import LeftArrow from "../General/Icon/LeftArrow";
import Textfields from "../General/Component/Textfields";
import strings from "../General/Constant/Locales/Strings/RegistLoginStrings";
import { EmailSchema } from "../General/Helper/validationField";

import classes from "./forgotReset.module.css";
import useSnackbar from "../General/Helper/useSnackbar";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import requestResetPassword from "./Helper/requestResetPassword";
import { useNavigate } from "react-router-dom";
import * as routeEnum from "../General/Constant/routes";

const ForgotPassword = () => {
  const { t } = useTranslation("RegistLogin");
  const [formData, setFormData] = useState({
    email: null,
    activeStep: 0,
    loadingSubmit: false,
  });
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const isValidEmail = EmailSchema(t).safeParse(formData.email).success;

  const isButtonDisabled = formData.loadingSubmit || !isValidEmail;

  const buttonClass = isValidEmail
    ? `${classes.buttonSubmit} ${classes.textBold600Darker}`
    : `${classes.buttonSubmitInactive} ${classes.textBold600Gray}`;

  const onSubmitResetPassword = () => {
    requestResetPassword(t, formData, setFormData, showSnackbar);
  };

  const backToPrevious = () => {
    if (formData.activeStep === 0) {
      navigate(routeEnum.LOGIN);
    } else {
      setFormData((prev) => ({ ...prev, activeStep: 0 }));
    }
  };

  return (
    <Grid>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <HeaderComponent />
      <div className={classes.divContainer}>
        <div className={classes.divFormContainer}>
          <Box className={classes.boxForm}>
            <IconButton
              onClick={() => backToPrevious()}
              className={classes.iconButton}
            >
              <LeftArrow />
            </IconButton>
            {formData.activeStep === 0 && (
              <>
                <div>
                  <Typography className={classes.textBold600H1}>
                    {t(strings.textResetPassword)}
                  </Typography>
                  <Typography className={classes.textRegularDarkGray}>
                    {t(strings.textResetPasswordDescription)}
                  </Typography>
                </div>

                <div className={classes.divFormField}>
                  <Typography className={classes.textBold600BlackH4}>
                    Email
                  </Typography>
                  <Textfields
                    isHideLabel
                    placeholder={t(strings.textPlaceholderEmail2)}
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <Button
                  fullWidth
                  variant="contained"
                  disabled={isButtonDisabled}
                  className={buttonClass}
                  onClick={() => onSubmitResetPassword()}
                >
                  {t(strings.textReset)}
                </Button>
              </>
            )}
            {formData.activeStep === 1 && (
              <>
                <div>
                  <Typography className={classes.textBold600H1}>
                    {t(strings.textCheckEmail)}
                  </Typography>
                  <Typography className={classes.textRegularDarkGray}>
                    <span>{t(strings.textResetPasswordSuccessDesc)}</span>
                    <span className={classes.textBold600DarkGray}>
                      {" " + formData.email}
                    </span>
                  </Typography>
                </div>
                <div className={classes.divFormField}>
                  <Typography className={classes.textRegular14}>
                    {t(strings.textAskNoEmailSent) + " "}
                    <span
                      onClick={() => onSubmitResetPassword()}
                      className={classes.textYellowRegular14}
                      style={{ cursor: "pointer" }}
                    >
                      {t(strings.textResendIt) + "."}
                    </span>
                  </Typography>
                </div>
              </>
            )}
          </Box>
        </div>
        <div className={classes.divFooter}>
          <Typography className={classes.textRegularGray500}>
            {t(strings.textCopyright)}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default ForgotPassword;
