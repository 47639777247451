import { Button } from "@mui/material";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import classes from "../Style/general.module.css";
import { useTranslation } from "react-i18next";
import PageWithIllustration from "../Component/PageWithIllustration";
import LeftArrowIcon from "../Icon/LeftArrowIcon";
import * as routeEnum from "../../General/Constant/routes";
import { useNavigate } from "react-router-dom";

const illustration =
  "https://res.cloudinary.com/pastisah-id/image/upload/v1724211165/not_found_ctdx99.png";

const NotFoundPage = () => {
  const { t } = useTranslation("General");
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(routeEnum.LOGIN);
  };

  return (
    <PageWithIllustration
      illustration={illustration}
      textTitle={t(strings.textPageNotFoundTitle)}
      textSubTitle={t(strings.textPageNotFoundSubtitle)}
      textErrorCode={t(strings.textPageNotFoundCode)}
      buttonAction={
        <div style={{ margin: "32px 0 " }}>
          <Button
            onClick={() => goToLogin()}
            className={`${classes.buttonSubmit} ${classes.textBold600Darker}`}
          >
            <LeftArrowIcon />
            {t(strings.textBack)}
          </Button>
        </div>
      }
    />
  );
};

export default NotFoundPage;
