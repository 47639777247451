import { Snackbar } from "@mui/material";
import SuccessIcon from "../Icon/SuccessIcon";
import ErrorIcon from "../Icon/ErrorIcon";
import WarningIcon from "../Icon/WarningIcon";
import InfoIcon from "../Icon/InfoIcon";
import classes from "../Style/general.module.css";

const SnackbarNotification = ({ snackbar, hideSnackbar }) => {
  const { open, message, severity } = snackbar;
  const getIcon = () => {
    if (severity)
      switch (severity) {
        case "success":
          return <SuccessIcon widthIcon={24} heightIcon={24} />;
        case "error":
          return <ErrorIcon widthIcon={24} heightIcon={24} />;
        case "warning":
          return <WarningIcon widthIcon={24} heightIcon={24} />;
        case "info":
          return <InfoIcon widthIcon={24} heightIcon={24} />;
        default:
          return null;
      }
  };
  const getClasess = () => {
    if (severity)
      switch (severity) {
        case "success":
          return classes.divSuccess;
        case "error":
          return classes.divFailed;
        case "warning":
          return classes.divWarning;
        case "info":
          return classes.divInfo;
        default:
          return classes.divInfo;
      }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={hideSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <div className={getClasess() + " " + classes.textRegularBlack12}>
        {getIcon()}
        {message}
      </div>
    </Snackbar>
  );
};

export default SnackbarNotification;
