import { Typography } from "@mui/material";
import classes from "../Style/general.module.css";

const getInitials = (fullName) => {
  if (!fullName) return "";

  const fullNameArray = fullName.split(" ");
  const initials = fullNameArray
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  return (
    <div className={classes.divInitial}>
      <Typography className={classes.textWhiteSemiBold14}>
        {initials}
      </Typography>
    </div>
  );
};

export default getInitials;
