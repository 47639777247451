const InfoIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 18}
    height={heightIcon || 18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM8.71875 5.0625C8.88563 5.0625 9.04876 5.11198 9.18752 5.2047C9.32627 5.29741 9.43441 5.42919 9.49828 5.58336C9.56214 5.73754 9.57885 5.90719 9.54629 6.07086C9.51373 6.23453 9.43337 6.38487 9.31537 6.50287C9.19737 6.62087 9.04703 6.70123 8.88336 6.73379C8.71969 6.76634 8.55004 6.74963 8.39586 6.68577C8.24169 6.62191 8.10991 6.51377 8.0172 6.37501C7.92449 6.23626 7.875 6.07313 7.875 5.90625C7.875 5.68247 7.9639 5.46786 8.12213 5.30963C8.28037 5.15139 8.49498 5.0625 8.71875 5.0625ZM9.5625 12.9375C9.26413 12.9375 8.97799 12.819 8.76701 12.608C8.55603 12.397 8.4375 12.1109 8.4375 11.8125V9C8.28832 9 8.14525 8.94074 8.03976 8.83525C7.93427 8.72976 7.875 8.58668 7.875 8.4375C7.875 8.28832 7.93427 8.14524 8.03976 8.03975C8.14525 7.93426 8.28832 7.875 8.4375 7.875C8.73587 7.875 9.02202 7.99353 9.233 8.2045C9.44398 8.41548 9.5625 8.70163 9.5625 9V11.8125C9.71169 11.8125 9.85476 11.8718 9.96025 11.9773C10.0657 12.0827 10.125 12.2258 10.125 12.375C10.125 12.5242 10.0657 12.6673 9.96025 12.7727C9.85476 12.8782 9.71169 12.9375 9.5625 12.9375Z"
      fill="#1677FF"
    />
  </svg>
);

export default InfoIcon;
