import {
  EmailSchema,
  FieldRequired,
  PasswordSchema,
  PhoneNumberSchema,
  RegularSchema,
} from "./validationField";

const handleChangeTextfield = (
  t,
  event,
  name,
  setValidationError,
  onChange,
  label,
  isValidate
) => {
  const { value } = event.target;
  let result;
  if (isValidate) {
    if (name.toLowerCase().includes("password")) {
      result = PasswordSchema(t).safeParse(value);
    } else if (name.includes("email")) {
      result = EmailSchema(t).safeParse(value);
    } else if (name.includes("phone")) {
      result = PhoneNumberSchema(t).safeParse(value);
    } else if (
      name.toLowerCase().includes("name") ||
      name.toLowerCase().includes("type") ||
      name.toLowerCase().includes("subject")
    ) {
      result = RegularSchema(t, label).safeParse(value);
    } else {
      result = FieldRequired(t, label).safeParse(value);
    }
  }

  if (result) {
    setValidationError(result.success ? "" : result.error.errors[0].message);
  }

  onChange(event);
};

export default handleChangeTextfield;
