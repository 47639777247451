import { Box, Button, CircularProgress, Typography } from "@mui/material";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import FormWithIllustration from "../General/Component/FormWithIllustration";
import { useTranslation } from "react-i18next";
import useSnackbar from "../General/Helper/useSnackbar";
import classes from "./login.module.css";
import strings from "../General/Constant/Locales/Strings/RegistLoginStrings";
import Textfields from "../General/Component/Textfields";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { EmailSchema, PasswordSchema } from "../General/Helper/validationField";
import handleResetForm from "../General/Helper/handleResetForm";
import * as routesEnum from "../General/Constant/routes";
import onSubmitLoginAccount from "./Helper/onSubmitLoginAccount";
import { useDispatch } from "react-redux";

const Login = () => {
  const { t } = useTranslation("RegistLogin");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const objectsData = [
    {
      name: "email",
      placeholder: t(strings.textPlaceholderEmail2),
      label: t(strings.textEmail),
      type: "text",
    },
    {
      name: "password",
      placeholder: t(strings.textPlaceholderPassword2),
      label: t(strings.textPassword),
      type: "password",
    },
  ];

  const [formData, setFormData] = useState(
    objectsData.reduce(
      (acc, field) => {
        if (objectsData.name) acc[objectsData.name] = "";
        return acc;
      },
      { loadingSubmit: false }
    )
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const resetForm = () => {
    handleResetForm(formData, setFormData); // Call the reset function
  };

  const submitLoginAccount = () => {
    setFormData((prev) => ({ ...prev, loadingSubmit: true }));
    onSubmitLoginAccount(
      t,
      formData,
      setFormData,
      resetForm,
      showSnackbar,
      navigate,
      dispatch
    );
  };

  const validEmail = EmailSchema(t).safeParse(formData.email);
  const validPassword = PasswordSchema(t).safeParse(formData.password);

  const handleDisabledButtonLogin = () => {
    return (
      formData.loadingSubmit && (!validEmail.success || !validPassword.success)
    );
  };

  const handleDisabledClassButtonLogin = () => {
    return handleDisabledButtonLogin()
      ? `${classes.buttonSubmitInactive} ${classes.textBold600Gray}`
      : `${classes.buttonSubmit} ${classes.textBold600Darker}`;
  };

  const goToRegistration = () => {
    navigate(routesEnum.REGISTRATION);
  };

  const showFormLogin = (
    <div className={classes.divFormContainer}>
      <div className={classes.divBoxForm}>
        <Typography className={classes.textBold}>
          {t(strings.textTagline)}
        </Typography>
        <Box
          component="form"
          noValidate
          autoComplete="off"
          className={classes.boxForm}
        >
          {objectsData.map((field, index) => {
            return (
              <div className={classes.divFormField} key={index}>
                <Typography className={classes.textBold600Black}>
                  {field.label}
                  {field.name === "address" && (
                    <span
                      className={classes.textGrayItalic12}
                      style={{ margin: "0 0 0 4px" }}
                    >
                      {t(strings.textOptional)}
                    </span>
                  )}
                </Typography>
                <Textfields
                  key={field.name}
                  isHideLabel={true}
                  placeholder={field.placeholder}
                  label={field.label}
                  type={field.type}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                />
              </div>
            );
          })}
        </Box>
      </div>
      <div className={classes.divButtonTnC}>
        <span className={classes.rowLeftAlign}>
          <Link
            to={routesEnum.FORGOT_PASSWORD}
            target="_blank"
            className={classes.textYellow12}
          >
            {t(strings.textForgetPassword) + "?"}
          </Link>
        </span>
        <div className={classes.groupButton}>
          <Button
            fullWidth
            disabled={handleDisabledButtonLogin()}
            variant="contained"
            className={handleDisabledClassButtonLogin()}
            onClick={submitLoginAccount}
          >
            {formData.loadingSubmit && <CircularProgress size={"1em"} />}
            {t(strings.textLogin)}
          </Button>
          <Button
            fullWidth
            variant="contained"
            className={`${classes.buttonSubmitOutlined} ${classes.textBold600H5}`}
            onClick={goToRegistration}
          >
            {t(strings.textRegister)}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />

      <FormWithIllustration content={showFormLogin} header={null} />
    </Box>
  );
};

export default Login;
