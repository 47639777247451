import { useNavigate } from "react-router-dom";
import classes from "../Style/general.module.css";
import SelectLanguage from "./SelectLanguage";
import * as routeEnum from "../Constant/routes";
import BurgerIcon from "../Icon/BurgerIcon";

const HeaderComponent = ({ userAction, isNavbar, handleOpenNavbar }) => {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate(routeEnum.LOGIN);
  };

  return (
    <div className={classes.divHeader}>
      <div className={classes.divLeftHeader}>
        {isNavbar && (
          <div className={classes.burgerIcon} onClick={handleOpenNavbar}>
            <BurgerIcon />
          </div>
        )}
        <img
          onClick={() => goToLogin()}
          src="https://res.cloudinary.com/pastisah-id/image/upload/v1723189534/digital-product-logo/web-illustration/Ifortepay192x192_hn8ntg.png"
          alt="logo"
          className={classes.logoHeader}
        />
      </div>
      <div className={classes.divEndOfHeader}>
        <SelectLanguage />
        {userAction}
      </div>
    </div>
  );
};

export default HeaderComponent;
