import encryptData from "./encryptData";

const headerRequest = () => {
  const token = localStorage.getItem("token");
  const XReqOnboarding = encryptData();
  let currentDate = new Date();
  const timestampInSeconds = Math.floor(currentDate.getTime() / 1000);
  return {
    "X-Req-Onboarding": XReqOnboarding,
    timestamp: timestampInSeconds,
    Authorization: token || "",
  };
};

export default headerRequest;
