const ExpandLessIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 16}
    height={heightIcon || 16}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M13.354 10.3537C13.3076 10.4001 13.2524 10.437 13.1917 10.4622C13.131 10.4873 13.066 10.5003 13.0003 10.5003C12.9346 10.5003 12.8695 10.4873 12.8088 10.4622C12.7481 10.437 12.693 10.4001 12.6465 10.3537L8.00028 5.70678L3.35403 10.3537C3.26021 10.4475 3.13296 10.5002 3.00028 10.5002C2.8676 10.5002 2.74035 10.4475 2.64653 10.3537C2.55271 10.2598 2.5 10.1326 2.5 9.99991C2.5 9.86722 2.55271 9.73997 2.64653 9.64615L7.64653 4.64615C7.69296 4.59967 7.74811 4.56279 7.80881 4.53763C7.86951 4.51246 7.93457 4.49951 8.00028 4.49951C8.06599 4.49951 8.13105 4.51246 8.19175 4.53763C8.25245 4.56279 8.30759 4.59967 8.35403 4.64615L13.354 9.64615C13.4005 9.69259 13.4374 9.74774 13.4626 9.80843C13.4877 9.86913 13.5007 9.9342 13.5007 9.99991C13.5007 10.0656 13.4877 10.1307 13.4626 10.1914C13.4374 10.2521 13.4005 10.3072 13.354 10.3537Z"
      fill="#707070"
    />
  </svg>
);

export default ExpandLessIcon;
