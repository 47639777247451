import { Grid, Typography } from "@mui/material";
import classes from "../Style/general.module.css";
import SelectLanguage from "./SelectLanguage";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as routeEnum from "../Constant/routes";

const image = {
  mainLogo:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1699865688/WhatsApp_Image_2023-10-03_at_18.17.21-removebg-preview_fgl2u3.png",
  imageFront:
    "https://res.cloudinary.com/pastisah-id/image/upload/v1722879460/digital-product-logo/web-illustration/header_hero_81b4ce92a0_dpxbiu.svg",
};

const FormWithIllustration = ({ content, header }) => {
  const { t } = useTranslation("General");
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate(routeEnum.LOGIN);
  };

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item className={classes.gridItemContainerLeft}>
        <div className={classes.gridImageSection}>
          <img
            onClick={() => goToLogin()}
            src={image.mainLogo}
            alt="logo"
            className={classes.mainLogo}
          />
          <div className={classes.divImageFront}>
            <img
              src={image.imageFront}
              alt="image_front"
              className={classes.imageFront}
            />
          </div>
        </div>
      </Grid>
      <Grid item className={classes.gridItemContainerRight}>
        <div className={classes.divPreRegist}>
          {!header && (
            <img
              src={image.mainLogo}
              alt="logo"
              className={classes.mainLogoMobile}
            />
          )}
          <SelectLanguage />
          {header}
        </div>
        {content}
        <div className={classes.divCopyright}>
          <Typography className={classes.textMiniGray500}>
            {t(strings.textCopyright)}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default FormWithIllustration;
