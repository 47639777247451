import { Drawer, useMediaQuery, useTheme } from "@mui/material";
import Steppers from "../../General/Component/Steppers";
import classes from "../RegistMerchant.module.css";

const NavbarStepper = ({
  openNavbar,
  handleOpenNavbar,
  stepNumber,
  listOutlinedStep,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Mobile: width <= 960px (or adjust based on your needs)

  return isMobile ? (
    <Drawer open={openNavbar} onClose={handleOpenNavbar}>
      <div className={classes.divLeftStep}>
        <div className={classes.divFixed}>
          <div className={classes.divStepper}>
            <Steppers
              activeStep={stepNumber.activeStepOutlined}
              stepList={listOutlinedStep}
              orientationStepper="vertical"
              dots={true}
            />
          </div>
        </div>
      </div>
    </Drawer>
  ) : (
    <div className={classes.divLeftStep}>
      <div className={classes.divFixed}>
        <div className={classes.divStepper}>
          <Steppers
            activeStep={stepNumber.activeStepOutlined}
            stepList={listOutlinedStep}
            orientationStepper="vertical"
            dots={true}
          />
        </div>
      </div>
    </div>
  );
};

export default NavbarStepper;
