const LeftArrowIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 20}
    height={heightIcon || 20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.5 10C17.5 10.1658 17.4342 10.3247 17.3169 10.4419C17.1997 10.5591 17.0408 10.625 16.875 10.625H4.6336L9.19219 15.1828C9.25026 15.2409 9.29632 15.3098 9.32775 15.3857C9.35918 15.4616 9.37535 15.5429 9.37535 15.625C9.37535 15.7071 9.35918 15.7884 9.32775 15.8643C9.29632 15.9402 9.25026 16.0091 9.19219 16.0672C9.13412 16.1253 9.06519 16.1713 8.98931 16.2027C8.91344 16.2342 8.83213 16.2503 8.75 16.2503C8.66788 16.2503 8.58656 16.2342 8.51069 16.2027C8.43482 16.1713 8.36589 16.1253 8.30782 16.0672L2.68282 10.4422C2.62471 10.3841 2.57861 10.3152 2.54715 10.2393C2.5157 10.1635 2.49951 10.0821 2.49951 10C2.49951 9.91786 2.5157 9.83653 2.54715 9.76066C2.57861 9.68479 2.62471 9.61585 2.68282 9.55781L8.30782 3.93281C8.42509 3.81553 8.58415 3.74965 8.75 3.74965C8.91586 3.74965 9.07492 3.81553 9.19219 3.93281C9.30947 4.05008 9.37535 4.20914 9.37535 4.375C9.37535 4.54085 9.30947 4.69991 9.19219 4.81718L4.6336 9.375H16.875C17.0408 9.375 17.1997 9.44084 17.3169 9.55805C17.4342 9.67527 17.5 9.83424 17.5 10Z"
      fill="#151313"
    />
  </svg>
);

export default LeftArrowIcon;
