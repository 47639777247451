import { List, ListItemButton, Popover, Typography } from "@mui/material";
import classes from "../Style/general.module.css";

const PopOverComponent = ({
  id,
  open,
  anchorEl,
  handleClose,
  listItems,
  selectedData,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        top: "10px",
        bgcolor: "transparent", // Background color
        boxShadow: "none", // Remove shadow
        "& .MuiPopover-paper": {
          bgcolor: "transparent", // Background color
          boxShadow: "none", // Remove shadow
        },
        zIndex: "1000",
      }}
      className={classes.popOver}
      disableScrollLock
    >
      <List className={classes.listItemUser}>
        {listItems.map((data, index) => (
          <ListItemButton
            key={index}
            onClick={() => data.handleItemButton(data.id)}
            selected={data.id === selectedData}
            className={classes.listItemUserButton}
          >
            {data.icon || null}
            <Typography
              className={
                data.type === "error"
                  ? classes.textErrorSemiBold14
                  : classes.textSemiBold
              }
            >
              {data.label}
            </Typography>
          </ListItemButton>
        ))}
      </List>
    </Popover>
  );
};

export default PopOverComponent;
