const strings = {
  textRegisterPreparation:
    "For a smooth registration process, prepare the following documents!",
  textDirectorEKTP: "Director's E-KTP",
  textAccountNumber: "Account Number",
  textCompanyNPWP: "Company NPWP",
  textPersonalNPWP: "Personal NPWP",
  textDeedEstablishmentAndAmendment: "Deed of Establishment & Amendment",
  textMinistryLawAndHumanRightsDecree:
    "Ministry of Law and Human Rights Decree",
  textTDPSE: "Electronic System Operator Registration Certificate (TD PSE)",
  textNIB: "Business Identification Number (NIB)",
  textInstitutionLicence: "Institution Licence",
  textContinueProcess: "Continue the Process",
  textOptional: "Optional",
  textLogout: "Log out",
  textMerchantRegistration: "Merchant Registration",
  textSupportingDocuments: "Supporting Documents",
  textVerificationProcess: "Verification Process",
  textActivationStatus: "Activation Status",
  textWebStaging: "Web Staging",
  textRegistrationInformation: "Registration Information",
  textPICName: "Name of Person in Charge",
  textBusinessInformation: "Business Information",
  textServicesAndFees: "Services & Fees",
};

export default strings;
