import CryptoJS from "crypto-js";

const decryptData = (paramData) => {
  try {
    const key = CryptoJS.enc.Utf8.parse(
      process.env.REACT_APP_SECRET_KEY_CRYPTO
    );

    const encryptedStr = CryptoJS.enc.Base64.parse(paramData).toString(
      CryptoJS.enc.Utf8
    );
    const decrypted = CryptoJS.AES.decrypt(encryptedStr, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    return false;
  }
};

export default decryptData;
