// RadioButtons.js
import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import classes from "../Style/general.module.css";

const RadioButtons = ({
  label,
  name,
  value,
  onChange,
  options,
  row = true, // Default to display in a row
  ...props
}) => {
  return (
    <FormControl component="fieldset" {...props}>
      <RadioGroup
        row={row}
        name={name || ""}
        value={value || ""}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={option.value || index}
            value={option.value || ""}
            control={
              <Radio
                sx={{
                  color: "#cfdfec", // color of the radio button when unselected
                  "&.Mui-checked": {
                    color: "#1263D4", // color of the radio button when selected
                  },
                }}
              />
            }
            label={option.label || ""}
            className={classes.textSemiBoldDarker}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;
