import axios from "axios";
import config from "../Constant/configAuth";
import Cookies from "js-cookie";

const RequestHttpGateway = axios.create({
  baseURL: config.urlGateway,
});

RequestHttpGateway.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.data?.response_message
        ?.toLowerCase()
        .includes("token authorization")
    ) {
      Cookies.remove("access_token");
      Cookies.remove("user_email");
      Cookies.remove("mitra_id");
      Cookies.remove("client_id");
      Cookies.remove("client_email");
      Cookies.remove("mitra_email");
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default RequestHttpGateway;
