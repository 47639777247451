const ErrorIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 18}
    height={heightIcon || 18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM11.648 10.852C11.7002 10.9043 11.7417 10.9663 11.77 11.0346C11.7983 11.1029 11.8128 11.1761 11.8128 11.25C11.8128 11.3239 11.7983 11.3971 11.77 11.4654C11.7417 11.5337 11.7002 11.5957 11.648 11.648C11.5957 11.7002 11.5337 11.7417 11.4654 11.77C11.3971 11.7983 11.3239 11.8128 11.25 11.8128C11.1761 11.8128 11.1029 11.7983 11.0346 11.77C10.9663 11.7417 10.9043 11.7002 10.852 11.648L9 9.79523L7.14797 11.648C7.09571 11.7002 7.03367 11.7417 6.96538 11.77C6.8971 11.7983 6.82391 11.8128 6.75 11.8128C6.67609 11.8128 6.60291 11.7983 6.53462 11.77C6.46634 11.7417 6.4043 11.7002 6.35203 11.648C6.29977 11.5957 6.25832 11.5337 6.23003 11.4654C6.20175 11.3971 6.18719 11.3239 6.18719 11.25C6.18719 11.1761 6.20175 11.1029 6.23003 11.0346C6.25832 10.9663 6.29977 10.9043 6.35203 10.852L8.20477 9L6.35203 7.14797C6.24649 7.04242 6.18719 6.89927 6.18719 6.75C6.18719 6.60073 6.24649 6.45758 6.35203 6.35203C6.45758 6.24648 6.60074 6.18719 6.75 6.18719C6.89927 6.18719 7.04242 6.24648 7.14797 6.35203L9 8.20477L10.852 6.35203C10.9043 6.29977 10.9663 6.25831 11.0346 6.23003C11.1029 6.20174 11.1761 6.18719 11.25 6.18719C11.3239 6.18719 11.3971 6.20174 11.4654 6.23003C11.5337 6.25831 11.5957 6.29977 11.648 6.35203C11.7002 6.40429 11.7417 6.46634 11.77 6.53462C11.7983 6.6029 11.8128 6.67609 11.8128 6.75C11.8128 6.82391 11.7983 6.8971 11.77 6.96538C11.7417 7.03366 11.7002 7.09571 11.648 7.14797L9.79524 9L11.648 10.852Z"
      fill="#EB5757"
    />
  </svg>
);

export default ErrorIcon;
