const WarningIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 16}
    height={heightIcon || 15}
    viewBox="0 0 16 15"
    fill="none"
  >
    <path
      d="M15.6498 12.2251L9.50095 1.54677C9.3473 1.28515 9.12794 1.06823 8.86463 0.917511C8.60131 0.76679 8.30318 0.6875 7.99978 0.6875C7.69638 0.6875 7.39825 0.76679 7.13493 0.917511C6.87161 1.06823 6.65226 1.28515 6.49861 1.54677L0.349778 12.2251C0.201936 12.4782 0.124023 12.766 0.124023 13.059C0.124023 13.3521 0.201936 13.6399 0.349778 13.8929C0.501463 14.1561 0.720444 14.3742 0.98425 14.5249C1.24806 14.6755 1.54719 14.7532 1.85095 14.75H14.1486C14.4521 14.753 14.751 14.6751 15.0145 14.5245C15.278 14.3739 15.4968 14.1559 15.6484 13.8929C15.7964 13.64 15.8746 13.3523 15.8748 13.0592C15.8751 12.7662 15.7974 12.4783 15.6498 12.2251ZM7.43728 6.31255C7.43728 6.16336 7.49654 6.02029 7.60203 5.9148C7.70752 5.80931 7.85059 5.75005 7.99978 5.75005C8.14896 5.75005 8.29204 5.80931 8.39753 5.9148C8.50302 6.02029 8.56228 6.16336 8.56228 6.31255V9.12505C8.56228 9.27423 8.50302 9.41731 8.39753 9.5228C8.29204 9.62828 8.14896 9.68755 7.99978 9.68755C7.85059 9.68755 7.70752 9.62828 7.60203 9.5228C7.49654 9.41731 7.43728 9.27423 7.43728 9.12505V6.31255ZM7.99978 12.5C7.8329 12.5 7.66977 12.4506 7.53102 12.3578C7.39226 12.2651 7.28412 12.1334 7.22025 11.9792C7.15639 11.825 7.13968 11.6554 7.17224 11.4917C7.2048 11.328 7.28516 11.1777 7.40316 11.0597C7.52116 10.9417 7.6715 10.8613 7.83517 10.8288C7.99884 10.7962 8.16849 10.8129 8.32267 10.8768C8.47684 10.9406 8.60862 11.0488 8.70133 11.1875C8.79404 11.3263 8.84353 11.4894 8.84353 11.6563C8.84353 11.8801 8.75463 12.0947 8.5964 12.2529C8.43817 12.4112 8.22355 12.5 7.99978 12.5Z"
      fill="#CAA406"
    />
  </svg>
);

export default WarningIcon;
