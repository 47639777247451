import strings from "../../General/Constant/Locales/Strings/RegistLoginStrings";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const onSubmitRegistAccount = (
  t,
  formData,
  setFormData,
  setOpenEmail,
  resetForm,
  showSnackbar
) => {
  const bodyRequest = {
    business_name: formData.businessName,
    business_type: formData.businessType,
    email: formData.email.toLowerCase(),
    fullname: formData.fullName,
    password: formData.password,
    phone: "+62" + formData.phoneNumber,
    address: formData.address,
  };

  const headers = headerRequest();
  RequestHttpGateway.post(`user-access/register`, bodyRequest, {
    headers,
  })
    .then((res) => {
      showSnackbar(t(strings.textSuccessRegist), "success");
      setOpenEmail({ email: res.data.data.user.email, open: true });
      resetForm();
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default onSubmitRegistAccount;
