import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import RegistLoginID from "./General/Constant/Locales/ID/RegistLogin.json";
import RegistLoginEN from "./General/Constant/Locales/EN/RegistLogin.json";

import GeneralID from "./General/Constant/Locales/ID/General.json";
import GeneralEN from "./General/Constant/Locales/EN/General.json";

import RegistMerchantID from "./General/Constant/Locales/ID/RegistMerchant.json";
import RegistMerchantEN from "./General/Constant/Locales/EN/RegistMerchant.json";

const resources = {
  id: {
    RegistLogin: RegistLoginID,
    General: GeneralID,
    RegistMerchant: RegistMerchantID,
  },
  en: {
    RegistLogin: RegistLoginEN,
    General: GeneralEN,
    RegistMerchant: RegistMerchantEN,
  },
};

const localLang = localStorage.getItem("lang");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localLang ? localLang : "id", // by default id

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
