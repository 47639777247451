import { jwtDecode } from "jwt-decode";

const decodeJWT = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  } catch (error) {
    return false;
  }
};
export default decodeJWT;
