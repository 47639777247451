// handleResetForm.js
const resetValues = (data) => {
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = key === "loadingSubmit" ? data[key] : ""; // Preserve loadingSubmit and set others to ""
    return acc;
  }, {});
};

const handleResetForm = (formData, setFormData) => {
  const updatedFormData = resetValues(formData);
  setFormData(updatedFormData); // Set state with the new values
};

export default handleResetForm;
