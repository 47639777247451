const SuccessIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 18}
    height={heightIcon || 18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      d="M9 1.6875C7.55373 1.6875 6.13993 2.11637 4.9374 2.91988C3.73486 3.72339 2.7976 4.86544 2.24413 6.20163C1.69067 7.53781 1.54586 9.00811 1.82801 10.4266C2.11017 11.8451 2.80661 13.148 3.82928 14.1707C4.85196 15.1934 6.15492 15.8898 7.57341 16.172C8.99189 16.4541 10.4622 16.3093 11.7984 15.7559C13.1346 15.2024 14.2766 14.2651 15.0801 13.0626C15.8836 11.8601 16.3125 10.4463 16.3125 9C16.3105 7.06123 15.5394 5.20246 14.1685 3.83154C12.7975 2.46063 10.9388 1.68955 9 1.6875ZM12.2105 7.71047L8.27297 11.648C8.22073 11.7003 8.15869 11.7418 8.09041 11.7701C8.02212 11.7984 7.94892 11.8129 7.875 11.8129C7.80108 11.8129 7.72789 11.7984 7.6596 11.7701C7.59131 11.7418 7.52928 11.7003 7.47703 11.648L5.78953 9.96047C5.68399 9.85492 5.62469 9.71177 5.62469 9.5625C5.62469 9.41323 5.68399 9.27008 5.78953 9.16453C5.89508 9.05898 6.03824 8.99969 6.1875 8.99969C6.33677 8.99969 6.47992 9.05898 6.58547 9.16453L7.875 10.4548L11.4145 6.91453C11.4668 6.86227 11.5288 6.82081 11.5971 6.79253C11.6654 6.76424 11.7386 6.74969 11.8125 6.74969C11.8864 6.74969 11.9596 6.76424 12.0279 6.79253C12.0962 6.82081 12.1582 6.86227 12.2105 6.91453C12.2627 6.96679 12.3042 7.02884 12.3325 7.09712C12.3608 7.1654 12.3753 7.23859 12.3753 7.3125C12.3753 7.38641 12.3608 7.4596 12.3325 7.52788C12.3042 7.59616 12.2627 7.65821 12.2105 7.71047Z"
      fill="#4FBA77"
    />
  </svg>
);

export default SuccessIcon;
