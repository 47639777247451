// validationSchemas.js
import { z } from "zod";
import strings from "../Constant/Locales/Strings/GeneralStrings";

const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}$/;
const regularRegex = /^[a-zA-Z\s.'-]+$/;
const phoneNumberRegex = /^8\d{7,10}$/;

export const FieldRequired = (t, label) =>
  z.string().min(1, label + " " + t(strings.textIsRequired));

export const EmailSchema = (t) =>
  z
    .string()
    .min(1, t(strings.textValidationEmailIsRequired))
    .email(t(strings.textValidationEmailFormat));

export const PhoneNumberSchema = (t) =>
  z
    .string()
    .min(1, t(strings.textValidationPhoneNumberIsRequired))
    .refine((value) => phoneNumberRegex.test(value), {
      message: t(strings.textValidationPhoneNumberFormat),
    });

export const RegularSchema = (t, label) =>
  z
    .string()
    .min(1, label + " " + t(strings.textIsRequired))
    .refine((value) => regularRegex.test(value), {
      message: label + " " + t(strings.textValidationLettersAndSomeSymbols),
    });

export const PasswordSchema = (t) =>
  z
    .string()
    .min(1, t(strings.textValidationPasswordIsRequired))
    .refine(
      (value) => {
        return passwordRegex.test(value);
      },
      { message: t(strings.textValidationPassword) }
    );
