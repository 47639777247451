import CryptoJS from "crypto-js";
const encryptPassword = (newPasswordInput) => {
  const key = process.env.REACT_APP_SECRET_KEY_CRYPTO;
  const key2 = CryptoJS.enc.Utf8.parse(key);

  const newPasswordEncryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(newPasswordInput),
    key2,
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const newPasswordEncryptedBase64Auth = CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(newPasswordEncryptedData.toString())
  );
  return newPasswordEncryptedBase64Auth;
};

export default encryptPassword;
