import { Button, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import HeaderComponent from "../../General/Component/HeaderComponent";
import PopOverComponent from "../../General/Component/PopOverComponent";
import Steppers from "../../General/Component/Steppers";
import getInitials from "../../General/Helper/getInitials";
import ExpandMoreIcon from "../../General/Icon/ExpandMoreIcon";
import ExpandLessIcon from "../../General/Icon/ExpandLessIcon";
import LogoutIcon from "../../General/Icon/LogoutIcon";
import * as routesEnum from "../../General/Constant/routes";
import strings from "../../General/Constant/Locales/Strings/RegistMerchantStrings";
import classes from "../RegistMerchant.module.css";
import NavbarStepper from "../Component/NavbarStepper";

const RegistrationForm = () => {
  const { t } = useTranslation("RegistMerchant");
  const navigate = useNavigate();
  const userDataLogin = useSelector((state) => state.user_data.userDataLogin);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [stepNumber, setStepNumber] = useState({
    activeStepOutlined: 0,
    activeStepOne: 0,
    activeStepTwo: 0,
    activeStepThree: 0,
    activeStepFour: 0,
    activeStepFive: 0,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const listOutlinedStep = [
    {
      id: 1,
      label: t(strings.textMerchantRegistration),
      description: "Description",
    },
    {
      id: 2,
      label: t(strings.textSupportingDocuments),
      description: "Description",
    },
    {
      id: 3,
      label: t(strings.textVerificationProcess),
      description: "Description",
    },
    {
      id: 4,
      label: t(strings.textActivationStatus),
      description: "Description",
    },
    { id: 5, label: t(strings.textWebStaging), description: "Description" },
  ];

  const listStep1 = [
    { id: 1, label: t(strings.textRegistrationInformation) },
    { id: 2, label: t(strings.textPICName) },
    { id: 3, label: t(strings.textBusinessInformation) },
    { id: 4, label: t(strings.textServicesAndFees) },
  ];

  const handleLogOut = () => {
    localStorage.clear();
    handleClose();
    navigate(routesEnum.LOGIN);
    setSelectedData("logOut");
  };

  const listItemUser = [
    {
      id: "logOut",
      label: t(strings.textLogout),
      icon: <LogoutIcon />,
      type: "error",
      handleItemButton: handleLogOut,
    },
  ];

  const handleClick = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const userActionLogin = (
    <Button
      className={classes.buttonUserName}
      aria-describedby={id}
      onClick={handleClick}
      disableRipple
    >
      <div className={classes.divProfile}>
        {getInitials(userDataLogin?.fullname)}
        <div className={classes.divName}>
          <Typography className={classes.textBlackSemiBold14}>
            {userDataLogin?.fullname}
          </Typography>
        </div>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </div>
    </Button>
  );

  const handleOpenNavbar = () => {
    setOpenNavbar(!openNavbar);
  };

  return (
    <>
      <PopOverComponent
        id={id}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        listItems={listItemUser}
        selectedData={selectedData}
      />

      <HeaderComponent
        userAction={userActionLogin}
        isNavbar={true}
        handleOpenNavbar={handleOpenNavbar}
      />

      <div className={classes.divContainer}>
        <div className={classes.divContainerForm}>
          {/* Left Stepper */}
          <NavbarStepper
            handleOpenNavbar={handleOpenNavbar}
            openNavbar={openNavbar}
            listOutlinedStep={listOutlinedStep}
            stepNumber={stepNumber}
          />
          {/* Right Stepper and Form */}
          <div className={classes.divRightStep}>
            <div className={classes.divStepperRight}>
              <div className={classes.divStepperHorizontal}>
                <Steppers
                  activeStep={stepNumber.activeStepOne}
                  stepList={listStep1}
                  orientationStepper="horizontal"
                  dots={false}
                />
              </div>
              <div className={classes.divForm}>session form ya ntar</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationForm;
