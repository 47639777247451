import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import classes from "../Style/general.module.css";
import CloseIconAction from "./CloseIconAction";

const DialogPopUp = ({ open, maxWidth, onClose, title, content, actions }) => {
  return (
    <Dialog open={open} maxWidth={maxWidth} fullWidth>
      <DialogTitle className={classes.divDialogTitle}>
        <Typography variant="h6">{title}</Typography>
        <CloseIconAction onClose={onClose} />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default DialogPopUp;
