import { IconButton } from "@mui/material";
import classes from "../Style/general.module.css";
import CloseIcon from "../Icon/CloseIcon";

const CloseIconAction = ({ onClose }) => {
  return (
    <IconButton onClick={onClose} className={classes.divClose}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseIconAction;
