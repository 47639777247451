import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import HeaderComponent from "../General/Component/HeaderComponent";
import LeftArrow from "../General/Icon/LeftArrow";
import Textfields from "../General/Component/Textfields";
import SnackbarNotification from "../General/Component/SnackbarNotification";
import InitialCheckIcon from "../General/Icon/InitialCheckIcon";
import SuccessIcon from "../General/Icon/SuccessIcon";

import classes from "./forgotReset.module.css";
import strings from "../General/Constant/Locales/Strings/RegistLoginStrings";
import useSnackbar from "../General/Helper/useSnackbar";
import createNewPassword from "./Helper/createNewPassword";
import * as routeEnum from "../General/Constant/routes";
import decodeJWT from "../General/Helper/decodeJWT";

const ResetPassword = () => {
  const { t, i18n } = useTranslation("RegistLogin");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { token } = useParams();

  const objectsData = [
    {
      name: "newPassword",
      placeholder: t(strings.textPlaceholderPasswordNew),
      label: t(strings.textNewPassword),
      type: "password",
    },
    {
      name: "retypePassword",
      placeholder: t(strings.textPlaceholderPasswordRetypeNew),
      label: t(strings.textRetypeNewPassword),
      type: "password",
    },
  ];

  const [formData, setFormData] = useState(() =>
    objectsData.reduce(
      (acc, field) => {
        acc[field.name] = "";
        return acc;
      },
      { loadingSubmit: false, activeStep: 0 }
    )
  );

  const [passwordValidationSeparated, setPasswordValidationSeparated] =
    useState([]);

  useEffect(() => {
    if (token) {
      const decodedToken = decodeJWT(token);
      if (decodedToken) {
        if (checkExpiry(decodedToken.exp)) {
          navigate(routeEnum.EXPIRED);
        }
      } else {
        navigate(routeEnum.ACCESS_DENIED);
      }
    }
  }, [token, navigate]);

  const checkExpiry = (expDate) => {
    const expiredDate = new Date(expDate);
    const currentDate = new Date();
    return expiredDate > currentDate;
  };

  useEffect(() => {
    const validationRules = [
      {
        rule: t(strings.textPasswordCondition1),
        validate: (password) => password.length >= 8,
        isValid: false,
      },
      {
        rule: t(strings.textPasswordCondition2),
        validate: (password) =>
          /[A-Z]/.test(password) &&
          /[a-z]/.test(password) &&
          /[0-9]/.test(password),
        isValid: false,
      },
      {
        rule: t(strings.textPasswordCondition3),
        validate: (password) => /[\W_]/.test(password),
        isValid: false,
      },
    ];

    setPasswordValidationSeparated(validationRules);
  }, [t, i18n.language]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    setPasswordValidationSeparated((prevValidations) =>
      prevValidations.map((validation) => ({
        ...validation,
        isValid: validation.validate(value),
      }))
    );
  };

  const isButtonDisabled =
    !formData.newPassword ||
    !formData.retypePassword ||
    !passwordValidationSeparated.every((validation) => validation.isValid) ||
    formData.newPassword !== formData.retypePassword;

  const buttonClass = isButtonDisabled
    ? `${classes.buttonSubmitInactive} ${classes.textBold600Gray}`
    : `${classes.buttonSubmit} ${classes.textBold600Darker}`;

  const onSubmitResetPassword = () => {
    createNewPassword(
      t,
      token,
      decodeJWT(token),
      formData,
      setFormData,
      showSnackbar
    );
  };

  const backToPrevious = () => {
    if (formData.activeStep === 0) {
      navigate(routeEnum.LOGIN);
    } else {
      setFormData((prev) => ({ ...prev, activeStep: 0 }));
    }
  };

  const goToLogin = () => {
    navigate(routeEnum.LOGIN);
  };

  const showPasswordValidation = () =>
    passwordValidationSeparated.map((data, index) => (
      <div className={classes.divPasswordCondition} key={index}>
        <div>
          {data.isValid ? (
            <SuccessIcon widthIcon={24} heightIcon={24} />
          ) : (
            <InitialCheckIcon widthIcon={24} heightIcon={24} />
          )}
        </div>
        <Typography>{data.rule}</Typography>
      </div>
    ));

  return (
    <Grid className={classes.gridContainer}>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
      <HeaderComponent />
      <div className={classes.divContainer}>
        {formData.activeStep === 0 && (
          <div className={classes.divFormContainer}>
            <Box className={classes.boxForm}>
              <IconButton
                onClick={() => backToPrevious()}
                className={classes.iconButton}
              >
                <LeftArrow />
              </IconButton>
              <div>
                <Typography className={classes.textBold600H1}>
                  {t(strings.textResetPassword)}
                </Typography>
              </div>
              {objectsData.map((field, index) => (
                <div className={classes.divFormField} key={index}>
                  <Typography className={classes.textBold600BlackH4}>
                    {field.label}
                  </Typography>
                  <Textfields
                    isHideLabel={true}
                    placeholder={field.placeholder}
                    label={field.label}
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    isValidate={false}
                  />
                </div>
              ))}
              <div className={classes.divPasswordState}>
                {showPasswordValidation()}
              </div>
              <Button
                fullWidth
                variant="contained"
                disabled={isButtonDisabled}
                className={buttonClass}
                onClick={() => onSubmitResetPassword()}
              >
                <span>
                  {formData.loadingSubmit && <CircularProgress size={"1em"} />}
                  {t(strings.textReset)}
                </span>
              </Button>
            </Box>
          </div>
        )}
        {formData.activeStep === 1 && (
          <Box className={classes.boxFormState}>
            <div className={classes.boxSuccessState}>
              <img
                src="https://res.cloudinary.com/pastisah-id/image/upload/v1723798662/digital-product-logo/web-illustration/SuccessIllustration_dxofod.png"
                className={classes.imageSuccess}
                alt=""
              />
              <div>
                <Typography className={classes.textBold600H1}>
                  {t(strings.textResetPasswordSuccess)}
                </Typography>
                <Typography className={classes.textRegularDarkGray}>
                  {t(strings.textResetPasswordSuccessDesc)}
                </Typography>
              </div>
            </div>
            <div className={classes.divFormField}>
              <Button
                fullWidth
                variant="contained"
                className={`${classes.buttonSubmit} ${classes.textBold600Darker}`}
                onClick={() => goToLogin()}
              >
                {t(strings.textGoToLogin)}
              </Button>
            </div>
          </Box>
        )}
        <div className={classes.divFooter}>
          <Typography className={classes.textRegularGray500}>
            {t(strings.textCopyright)}
          </Typography>
        </div>
      </div>
    </Grid>
  );
};

export default ResetPassword;
