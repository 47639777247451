const SendIcon = ({ widthIcon, heightIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 20}
    height={heightIcon || 20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M17.7593 2.24083C17.6021 2.08375 17.4059 1.97141 17.1909 1.91538C16.9758 1.85935 16.7497 1.86167 16.5359 1.92208H16.5242L1.52884 6.47208C1.2854 6.54224 1.06903 6.68472 0.908388 6.88063C0.747752 7.07654 0.650437 7.31664 0.629342 7.56911C0.608246 7.82158 0.664365 8.0745 0.790262 8.29436C0.916158 8.51421 1.10589 8.69062 1.33431 8.80021L7.96869 12.0315L11.1952 18.6619C11.2956 18.8761 11.4552 19.0571 11.6551 19.1834C11.855 19.3098 12.0869 19.3764 12.3234 19.3752C12.3593 19.3752 12.3952 19.3736 12.4312 19.3705C12.6835 19.3501 12.9234 19.253 13.1189 19.0923C13.3144 18.9315 13.4561 18.7149 13.5249 18.4713L18.0718 3.47599C18.0718 3.47208 18.0718 3.46818 18.0718 3.46427C18.133 3.25096 18.1363 3.0252 18.0814 2.81019C18.0265 2.59518 17.9153 2.39866 17.7593 2.24083ZM12.3304 18.1135L12.3265 18.1244V18.119L9.19681 11.6893L12.9468 7.93927C13.0591 7.82111 13.1207 7.66376 13.1186 7.50079C13.1166 7.33781 13.0509 7.1821 12.9356 7.06685C12.8204 6.9516 12.6647 6.88593 12.5017 6.88384C12.3387 6.88175 12.1814 6.94341 12.0632 7.05568L8.31322 10.8057L1.88119 7.67599H1.87572H1.88665L16.8749 3.12521L12.3304 18.1135Z"
      fill="#151313"
    />
  </svg>
);

export default SendIcon;
