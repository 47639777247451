import strings from "../../General/Constant/Locales/Strings/RegistLoginStrings";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";

import encryptPassword from "../../General/Helper/encryptPassword";
import headerRequest from "../../General/Helper/headerRequest";

const createNewPassword = (
  t,
  token,
  decodedToken,
  formData,
  setFormData,
  showSnackbar
) => {
  setFormData((prev) => ({ ...prev, loadingSubmit: true }));
  const bodyRequest = {
    email: decodedToken.email, // mandatory
    new_password: encryptPassword(formData.retypePassword),
    is_reset_password: true,
  };

  const headers = headerRequest(token);
  RequestHttpGateway.put(`user-access/password/setup`, bodyRequest, {
    headers,
  })
    .then((res) => {
      showSnackbar(res.data.response_message, "success");
      setFormData((prev) => ({
        ...prev,
        activeStep: 1,
        email: res.data.data.email,
      }));
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default createNewPassword;
