import strings from "../../General/Constant/Locales/Strings/RegistLoginStrings";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const requestResetPassword = (t, formData, setFormData, showSnackbar) => {
  setFormData((prev) => ({ ...prev, loadingSubmit: true }));
  const bodyRequest = {
    email: formData.email, // mandatory
  };

  const headers = headerRequest();
  RequestHttpGateway.post(`user-access/forgot/pass`, bodyRequest, {
    headers,
  })
    .then((res) => {
      showSnackbar(res.data.response_message, "success");
      setFormData((prev) => ({ ...prev, activeStep: 1 }));
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default requestResetPassword;
