import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Textfields from "./Textfields";
import classes from "../Style/general.module.css";
import { useTranslation } from "react-i18next";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import { useState } from "react";
import CloseIconAction from "./CloseIconAction";
import { EmailSchema, PhoneNumberSchema } from "../Helper/validationField";
import SendIcon from "../Icon/SendIcon";
import sendMessageToEmail from "../Helper/sendMessageToEmail";
import useSnackbar from "../Helper/useSnackbar";
import SnackbarNotification from "./SnackbarNotification";

const DialogFormPopUp = ({
  open,
  maxWidth,
  title,
  subTitle,
  content,
  onClose,
}) => {
  const { t } = useTranslation("General");
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  const objectsData = [
    {
      name: "fullName",
      placeholder: t(strings.textPlaceholderFullName),
      label: t(strings.textFullName),
      type: "text",
      value: "",
    },
    {
      name: "email",
      placeholder: t(strings.textPlaceholderEmail),
      label: t(strings.textEmail),
      type: "email",
      value: "",
    },
    {
      name: "subject",
      placeholder: t(strings.textPlaceholderSubject),
      label: t(strings.textEmailSubject),
      type: "text",
      value: "",
    },
    {
      name: "companyName",
      placeholder: t(strings.textPlaceholderCompanyName),
      label: t(strings.textCompanyName),
      type: "text",
      value: "",
    },
    {
      name: "phoneNumber",
      placeholder: t(strings.textPlaceholderPhoneNumber),
      label: t(strings.textPhoneNumber),
      type: "text",
      value: "",
    },
    {
      name: "message",
      placeholder: t(strings.textPlaceholderMessage),
      label: t(strings.textMessage),
      type: "text",
      value: "",
    },
  ];

  const listSubject = [
    {
      id: "Status Aktivasi Gagal",
      label: t(strings.textFailedActivationStatus),
    },
    {
      id: "Biaya Administrasi Pembayaran",
      label: t(strings.textPaymentAdministrationFee),
    },
    {
      id: "Proses Verifikasi Merchant",
      label: t(strings.textMerchantVerificationProcess),
    },
    {
      id: "Kendala Lainnya",
      label: t(strings.textAnotherIssues),
    },
  ];

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    companyName: "",
    phoneNumber: "",
    message: "",
    loadingSubmit: false,
  });

  const validEmail = EmailSchema(t).safeParse(formData.email);
  const validPhone = PhoneNumberSchema(t).safeParse(formData.phoneNumber);

  const isButtonDisabled =
    !formData.fullName ||
    !validEmail.success ||
    !formData.email ||
    !formData.subject ||
    !formData.companyName ||
    !formData.phoneNumber ||
    !validPhone.success ||
    !formData.message ||
    formData.loadingSubmit;

  const buttonClass = isButtonDisabled
    ? `${classes.buttonSubmitInactive} ${classes.textBold600Gray}`
    : `${classes.buttonSubmit} ${classes.textBold600Darker}`;

  const formContactUs = () => (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.boxFormState}
    >
      {objectsData.map((field, index) => {
        if (field.name !== "subject") {
          return (
            <div className={classes.divFormField} key={index}>
              <Typography className={classes.textBold600BlackH4}>
                {field.label}
              </Typography>
              <Textfields
                key={field.name}
                isHideLabel={true}
                placeholder={field.placeholder}
                label={field.label}
                type={field.type}
                name={field.name}
                value={formData[field.name]}
                onChange={handleChangeContactUs}
              />
            </div>
          );
        } else {
          return (
            <div className={classes.divFormField} key={index}>
              <Typography className={classes.textBold600BlackH4}>
                {field.label}
              </Typography>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  value={formData.subject}
                  name={field.name}
                  onChange={handleChangeContactUs}
                  className={classes.selectOption}
                >
                  <MenuItem value="" disabled>
                    -- {t(strings.textPlaceholderSubject)} --
                  </MenuItem>
                  {listSubject.map((data, index) => (
                    <MenuItem value={data.id} key={index}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          );
        }
      })}

      <Button
        fullWidth
        variant="contained"
        disabled={isButtonDisabled}
        className={buttonClass}
        onClick={confirmContactUs}
      >
        <span className={classes.divAllCenter} style={{ gap: "8px" }}>
          {formData.loadingSubmit && <CircularProgress size={"1em"} />}
          {t(strings.textSendMessage)}
          <SendIcon />
        </span>
      </Button>
    </Box>
  );

  const handleChangeContactUs = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const confirmContactUs = () => {
    sendMessageToEmail(
      setFormData,
      formData,
      showSnackbar,
      t,
      strings,
      handleDialogClose
    );
  };

  const resetForm = () => {
    setFormData({
      fullName: "",
      email: "",
      subject: "",
      companyName: "",
      phoneNumber: "",
      message: "",
      loadingSubmit: false,
    });
  };

  const handleDialogClose = () => {
    resetForm();
    onClose();
  };

  return (
    <>
      <SnackbarNotification snackbar={snackbar} hideSnackbar={hideSnackbar} />
      <Dialog open={open} onClose={handleDialogClose} maxWidth={maxWidth}>
        <div className={classes.divCloseDialogContainer}>
          <div className={classes.dialogForm}>
            <div className={classes.divClose}>
              <CloseIconAction onClose={handleDialogClose} />
            </div>

            <DialogTitle className={classes.divDialogTitleImage}>
              <Typography className={classes.textSuperBold18}>
                {title || t(strings.textSendEmail)}
              </Typography>
              <Typography className={classes.textRegularDarkGray14}>
                {subTitle || t(strings.textSubtitleContactUs)}
              </Typography>
            </DialogTitle>
            <DialogContent>{content || formContactUs()}</DialogContent>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default DialogFormPopUp;
