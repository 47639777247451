import { Button, Grid } from "@mui/material";
import { useState } from "react";
import DialogFormPopUp from "../Component/DialogFormPopUp";

const AccessDenied = () => {
  const [openContactUs, setOpenContactUs] = useState(false);

  const handleOpenContactUs = () => {
    setOpenContactUs(true);
  };

  const handleCloseContactUs = () => {
    setOpenContactUs(false);
  };
  return (
    <Grid>
      <h1> AccessDenied is Coming Soon...</h1>
      <Button onClick={handleOpenContactUs}>Contact Us</Button>
      <DialogFormPopUp
        open={openContactUs}
        onClose={handleCloseContactUs}
        maxWidth={"xs"}
      />
    </Grid>
  );
};

export default AccessDenied;
