import strings from "../../General/Constant/Locales/Strings/RegistLoginStrings";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

import * as routesEnum from "../../General/Constant/routes";
import { fetchUserProfile } from "../../Store/userSlice";

const onSubmitLoginAccount = (
  t,
  formData,
  setFormData,
  resetForm,
  showSnackbar,
  navigate,
  dispatch
) => {
  const bodyRequest = {
    email: formData.email, // mandatory
    password: formData.password, // mandatory
  };

  const headers = headerRequest();
  RequestHttpGateway.post(`user-access/login`, bodyRequest, {
    headers,
  })
    .then((res) => {
      localStorage.setItem("token", res.data.data.jwt);
      dispatch(fetchUserProfile());
      showSnackbar(t(strings.textSuccessRegist), "success");
      resetForm();
      navigate(routesEnum.REGISTRATION_MERCHANT);
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    })
    .finally(() => {
      setFormData((prev) => ({ ...prev, loadingSubmit: false }));
    });
};

export default onSubmitLoginAccount;
