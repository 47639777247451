import strings from "../../General/Constant/Locales/Strings/RegistLoginStrings";
import RequestHttpGateway from "../../General/Helper/RequestHttpGateway";
import headerRequest from "../../General/Helper/headerRequest";

const resentEmail = (t, emailResent, setOpenEmail, showSnackbar) => {
  const headers = headerRequest();

  RequestHttpGateway.post(
    `user-access/resend/activation`,
    { email: emailResent },
    { headers }
  )
    .then((res) => {
      showSnackbar(res.data.response_message, "success");
      setOpenEmail({ email: "", open: false });
    })
    .catch((err) => {
      showSnackbar(
        err.response.data.response_message ||
          t(strings.textServiceNotAvailable),
        "error"
      );
    });
};

export default resentEmail;
