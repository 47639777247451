import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import getUserProfileData from "../General/Helper/getUserProfileData";

const initialState = {
  token: null,
  userDataLogin: null,
  loading: false,
  error: null,
};

// use thunk to handle async API
export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      try {
        const response = await getUserProfileData();
        return response.data.data; // Return data from the response
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  }
);

const userSlice = createSlice({
  name: "user_data",
  initialState,
  reducers: {
    fetchUserData(state, action) {
      state.userDataLogin = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userDataLogin = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { fetchUserData } = userSlice.actions;

export default userSlice.reducer;
