import { IconButton, TextField, Tooltip, Typography } from "@mui/material";
import VisibleIcon from "../Icon/VisibleIcon";
import VisibilityOffIcon from "../Icon/VisibilityOffIcon";
import classes from "../Style/general.module.css";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import strings from "../Constant/Locales/Strings/GeneralStrings";
import handleChangeTextfield from "../Helper/handleChangeTextfield";

const PhoneAdornment = () => (
  <div className={classes.divPhoneAdornment}>
    <Typography>+62</Typography>
    <div className={classes.divLine} />
  </div>
);

const PasswordAdornment = ({ isVisible, toggleVisibility, tooltipText }) => (
  <Tooltip title={tooltipText}>
    <IconButton onClick={toggleVisibility}>
      {isVisible ? <VisibilityOffIcon /> : <VisibleIcon />}
    </IconButton>
  </Tooltip>
);

const Textfields = ({
  isHideLabel,
  placeholder,
  name,
  label,
  type = "text",
  value,
  onChange,
  error,
  helperText,
  fullWidth = true,
  isValidate = true,
  ...props
}) => {
  const { t } = useTranslation("General");
  const [isVisible, setIsVisible] = useState(false);
  const [validationError, setValidationError] = useState("");

  const handleVisibilityToggle = () => setIsVisible(!isVisible);

  const handleChange = (event) => {
    handleChangeTextfield(
      t,
      event,
      name,
      setValidationError,
      onChange,
      label,
      isValidate
    );
  };

  return (
    <TextField
      placeholder={placeholder || ""}
      name={name || ""}
      label={isHideLabel ? null : label}
      type={type === "password" ? (isVisible ? "text" : "password") : type}
      value={value || ""}
      onChange={handleChange}
      error={error || Boolean(validationError)}
      helperText={helperText || validationError}
      multiline={name.includes("address") || name.includes("message")}
      fullWidth={fullWidth}
      variant="outlined"
      {...props}
      InputProps={{
        startAdornment: name.includes("phone") && <PhoneAdornment />,
        endAdornment: type === "password" && (
          <PasswordAdornment
            isVisible={isVisible}
            toggleVisibility={handleVisibilityToggle}
            tooltipText={isVisible ? t(strings.textHide) : t(strings.textView)}
          />
        ),
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px solid #cfdfec",
            display: "flex",
            padding: "14px 12px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            borderRadius: "8px",
            fontSize: "14px",
          },
        },
      }}
    />
  );
};

export default Textfields;
