import { Navigate, Outlet } from "react-router-dom";
import * as routeEnum from "../../General/Constant/routes";

const PrivateRoute = () => {
  const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    return !!token;
  };

  return isAuthenticated() ? <Outlet /> : <Navigate to={routeEnum.LOGIN} />;
};

export default PrivateRoute;
