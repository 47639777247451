const VisibleIcon = ({ heightIcon, widthIcon }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={widthIcon || 24}
    height={heightIcon || 24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M23.1853 11.6962C23.1525 11.6222 22.3584 9.86062 20.5931 8.09531C18.2409 5.74312 15.27 4.5 12 4.5C8.72999 4.5 5.75905 5.74312 3.40687 8.09531C1.64155 9.86062 0.843741 11.625 0.814679 11.6962C0.772035 11.7922 0.75 11.896 0.75 12.0009C0.75 12.1059 0.772035 12.2097 0.814679 12.3056C0.847491 12.3797 1.64155 14.1403 3.40687 15.9056C5.75905 18.2569 8.72999 19.5 12 19.5C15.27 19.5 18.2409 18.2569 20.5931 15.9056C22.3584 14.1403 23.1525 12.3797 23.1853 12.3056C23.2279 12.2097 23.25 12.1059 23.25 12.0009C23.25 11.896 23.2279 11.7922 23.1853 11.6962ZM12 15.75C11.2583 15.75 10.5333 15.5301 9.9166 15.118C9.29992 14.706 8.81927 14.1203 8.53544 13.4351C8.25161 12.7498 8.17735 11.9958 8.32205 11.2684C8.46674 10.541 8.82389 9.8728 9.34834 9.34835C9.87279 8.8239 10.541 8.46675 11.2684 8.32205C11.9958 8.17736 12.7498 8.25162 13.4351 8.53545C14.1203 8.81928 14.7059 9.29993 15.118 9.91661C15.5301 10.5333 15.75 11.2583 15.75 12C15.75 12.9946 15.3549 13.9484 14.6516 14.6517C13.9484 15.3549 12.9946 15.75 12 15.75Z"
      fill="#A0989A"
    />
  </svg>
);

export default VisibleIcon;
